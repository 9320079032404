

import UMUM from  '../../library/umum'


export default {
  data() {
    return {

        form : {
            group : '',
            router : '/',
            nama : '',
            type : 'single',
            icon_color : 'white',
            text_color : '#dfdfdf',
            icon : 'home',
            color : 'light-green-7',
            kategori : 'menu',
            path : false,
            index : ''
        },

        subform : {
            menu : '',
            router : '/',
            nama : '',
            type : 'single',
            kategori : 'submenu',
            path : false
        },

        subsubform : {
            menu : '',
            router : '/',
            nama : '',
            type : 'single',
            kategori : 'subsubmenu',
            path : false
        },
        
        
     
        list_data : [],

        page_limit : 8,
        page_first: 1,
        page_last: 0,
        cari_value: "",
        cek_load_data : true,


        mdl_add: false,
        mdl_edit: false,
        mdl_hapus : false,
        btn_add: false,

        mdl_add1: false,
        mdl_edit1: false,
        mdl_hapus1 : false,
        btn_add1: false,

        mdl_add2: false,
        mdl_edit2: false,
        mdl_hapus2 : false,
        btn_add2: false,
    }
  },
  methods: {


    getView : function(){
      this.$store.commit("shoWLoading");
      fetch(this.$store.state.url.URL_MENU_LIST + "view", {
          method: "POST",
          headers: {
          "content-type": "application/json",
          authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({
              data_ke: this.page_first,
              page_limit : this.page_limit,
              cari_value: this.cari_value
          })
      })
          .then(res => res.json())
          .then(res_data => {
              this.list_data = res_data;
            //   this.page_last = res_data.jmlData;
              this.$store.commit("hideLoading");
              console.log(res_data);
      });
    },


    addData : function(data) {
        if (this.form.type == 'single') {
            this.form.path = true;
        } else {
            this.form.path = false;
        }
        this.form.createdAt = UMUM.NOW();
        fetch(this.$store.state.url.URL_MENU_LIST + "addData", {
            method: "POST",
            headers: {
                "content-type": "application/json",
                authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify(data)
        }).then(res_data => {
            this.Notify('Sukses Menambah Data', 'primary', 'check_circle_outline');
            this.getView();
        });

        // console.log(this.form)


    },


    editData : function(data){
      fetch(this.$store.state.url.URL_MENU_LIST + "editData", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify(data)
      }).then(res_data => {
          this.Notify('Sukses Merubah Data', 'warning', 'check_circle_outline');
          this.getView();
      });
    },

    removeData : function(data){
      fetch(this.$store.state.url.URL_MENU_LIST + "removeData", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify(data)
      }).then(res_data => {
          this.Notify('Sukses Menghapus Data', 'negative', 'check_circle_outline');
          this.getView();
      });

    },
    selectData : function(data){
        this.form = data;
        this.subform.menu = data._id;
    },
    selectData1 : function(data){
        this.subform = data;
        this.subsubform.menu = data._id;
    },

    selectData2 : function(data){
        this.subsubform = data;
    },

    // ====================================== PAGINATE ====================================
        Notify : function(message, positive, icon){
          this.$q.notify({
            message: message,
            color: positive,
            icon: icon,
            position : 'top',
            timeout: 500,
          })
        },

        cari_data : function(){
            this.page_first = 1;
            this.getView();
        },

        indexing : function(index){
            var idx = ((this.page_first-1)*this.page_limit)+index
            return idx
        },

        cekLength : function(data){
           if (data.submenu.length <= 0  ) {
             return true
           } else {
             return false
           }
        }




    // ====================================== PAGINATE ====================================







  },

  mounted () {
    this.getView();
  },
}
